export default {
  init() {
    // JavaScript to be fired on all pages
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
      $(document).ready(function () {
          $('[data-toggle="offcanvas"]').click(function () {
              $('.offcanvas').toggleClass('active');
          });
      });


  },
};

export default {
  init() {
    // JavaScript to be fired on the home page
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
      var owl = $('.owl-carousel');
      console.log("in owl carousel");
      owl.owlCarousel({
          items:6,
          loop:true,
          margin:20,
          autoWidth:false,
          autoplay:true,
          autoplaySpeed:650,
          autoplayTimeout:1000,
          autoplayHoverPause:true,
          responsive:{
              0 : {
                  items:2,
                  loop:true,
                  margin:20,
                  autoWidth:false,
                  autoplay:true,
                  autoplaySpeed:450,
                  autoplayTimeout:1000,
                  autoplayHoverPause:true,
              },
              // breakpoint from 480 up
              768 : {
                  items:6,
                  loop:true,
                  margin:20,
                  autoWidth:false,
                  autoplay:true,
                  autoplaySpeed:450,
                  autoplayTimeout:1000,
                  autoplayHoverPause:true,
              },
          },
      });


  },
};
